@use "./function" as *;

.switch {
  display: flex;
  background: #0d0d0d;
  backdrop-filter: blur(10px);
  width: 100%;
  min-height: rem(71);
  flex-direction: row;
  gap: 3rem;
  top: rem(50);
  z-index: 200;
  position: fixed;
  padding-block: rem(18);
  padding-inline: 10rem;
  justify-content: space-between;
  @media screen and (max-width: em(1100)) {
    padding-inline: 5rem;
  }
  @media screen and (max-width: em(750)) {
    top: rem(40);
    padding-inline: 3rem;
  }
  @media screen and (max-width: em(500)) {
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: em(400)) {
    flex-direction: column;
    gap: 1.5rem;
  }
  @media screen and (min-width: 1600px){
    padding-inline: 13rem;
  }
  div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }

  .create-more {
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: em(400)) {
      justify-content: flex-start;
    }
    .mint-btn {
      background-color: white;
      padding: 0.5rem 0.8rem;
      font-weight: 600;
      font-size: rem(15);
      border-radius: 5px;
    }
  }

  & li {
    display: inline-grid;
    place-content: center;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      left: 50%;
      width: 0;
      margin-inline: auto;
      bottom: -2px;
      background-color: white;
      transition: all 250ms linear;
    }

    &:hover::after {
      width: 100%;
      left: 0;
      transition: all 250ms linear;
    }

    &.active::after {
      width: 100%;
      left: 0;
    }
  }
}

.tokenPage {
  background: #0d0d0d;
  backdrop-filter: blur(10px);
  min-height: 100vh;
  margin-block-start: rem(100);
  padding-inline: 10rem;
  padding-block: 2rem;
  @media screen and (max-width: em(1100)) {
    padding-inline: 5rem !important;
  }
  @media screen and (max-width: em(750)) {
    padding-inline: 3rem !important;
  }
  .loader-fetch {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: rem(100);
    justify-content: center;
    flex: 1;
  }
  .nodata-container {
    padding-block: rem(100);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: rem(5);

    .msg-heading {
      font-size: rem(22.5);
      font-weight: 500;
    }

    .msg-subheading {
      font-size: rem(15);
    }

    .deploy-contract-btn {
      padding-inline: em(30);
      padding-block: em(10);
      font-size: rem(15);
      font-weight: 600;
      border-radius: 5px;
      margin-top: rem(35);
    }
  }

  .main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    margin-block-start: rem(40);
    justify-content: flex-start;
    column-gap: 10px;
    row-gap: 20px;
    .containers {
      flex-basis: calc(25% - 7.5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      overflow: hidden;
      @media screen and (max-width: em(1200)) {
        flex-basis: calc(33.42% - 7.5px);
      }
      @media screen and (max-width: em(700)) {
        flex-basis: calc(50.4% - 7.5px);
      }
      @media screen and (max-width: em(500)) {
        flex-basis: 100%;
      }
      .edition {
        position: absolute;
        padding: rem(8);
        border-radius: rem(8);
        background: #afafaf;
        color: black;
        font-size: rem(15);
        margin: rem(10);
        font-weight: 400;
      }
      .container-image {
        display: grid;
        aspect-ratio: 1;
        max-height: rem(250);
        width: 100%;
        img {
          aspect-ratio: 1;
          max-height: rem(250);
          object-fit: cover;
          object-position: top;
          height: 100%;
          width: 100%;
          @media screen and (max-width: em(500)) {
            max-height: rem(350);
          }
        }
        @media screen and (max-width: em(500)) {
          max-height: rem(350);
        }
      }
      .containers-details {
        // height: 30%;
        padding-inline: 1.1rem;
        padding-block: 0.8rem;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        color: white;
        background-color: black;
        .containers-details-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
        }
        .containers-details-name {
          font-size: rem(20);
          padding-inline: rem(5);
          font-weight: 600;
        }
        p:nth-of-type(2) {
          font-size: rem(18);
        }
        .containers-details-lower {
          padding-block-start: 0.75rem;
          .lower {
            display: flex;
            flex-direction: row;
            div {
              margin-block: auto;
              margin-inline-start: rem(8);
              p:nth-of-type(2) {
                margin-top: rem(3);
                font-size: rem(14);
                font-weight: 400;
              }
              p:nth-of-type(1) {
                color: #afafaf;
                font-size: rem(10);
                text-transform: uppercase;
                font-weight: 200;
              }
            }
            img {
              width: rem(35);
              aspect-ratio: 1;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
